import { FileCopy } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import { useCopyClipboard } from "../../../hooks";
import { TextField } from "../../TextField";
import { Tooltip } from "../../Tooltip";
import {
  Asterisk,
  LabelWrapper,
  StyledBox,
  StyledErrorMessage,
  StyledIconButton
} from "../styled-components";

const StyledTextField = styled(TextField)(
  ({ theme: { spacing, palette }, error, disabled, width }) => ({
    width,
    "& .MuiInputBase-root": {
      width: "100%"
    },
    "& input": {
      color: disabled ? palette.text.secondary : "white"
    },
    "& .MuiOutlinedInput-root": {
      height: 40,
      background: disabled ? "#424242" : "transparent",
      "& fieldset": {
        border: error
          ? `1px solid ${palette.custom.orange[400]}`
          : `1px solid ${palette.text.secondary}`
      },
      "&:hover fieldset": {
        border: "1px solid white"
      },
      "&.Mui-focused fieldset": {
        border: error
          ? `1px solid ${palette.custom.orange[400]}`
          : "1px solid white"
      },
      "&.Mui-error.MuiOutlinedInput-root": {
        border: `1px solid ${palette.custom.orange[400]}`
      }
    },
    "& .MuiFormHelperText-root": {
      marginTop: spacing(1)
    }
  })
);

const numberCharacters = /^[0-9-.]*$/;

export const LabelTextField = ({
  title,
  required = false,
  name,
  formik,
  copyValue = false,
  disabled = false,
  width = 260,
  labelWidth = "100%",
  rootWidth,
  fontSize = 14,
  type,
  list,
  select = false,
  ...props
}) => {
  const [isCopied, copyToClipboard] = useCopyClipboard();

  const handleCopy = () => copyToClipboard(formik.values[name]);

  const handleChange = event => {
    const { value } = event.target;
    if (type === "number" && !numberCharacters.test(value)) return;
    formik.handleChange(event);
  };

  const value =
    typeof formik.values[name] === "string"
      ? formik.values[name]?.trimStart()
      : formik.values[name];

  const hasError = formik.touched[name] && Boolean(formik.errors[name]);

  const handleBlur = event => {
    const { name, value } = event.target;

    if (type === "number" && value !== "" && !isNaN(value)) {
      formik.setFieldValue(name, Number(value));
    }

    formik.handleBlur(event);
  };

  return (
    <StyledBox className="input-root" width={rootWidth}>
      <LabelWrapper variant="h4" fontSize={fontSize} width={labelWidth}>
        {title}
        {required && <Asterisk>*</Asterisk>}
      </LabelWrapper>
      <StyledTextField
        variant="outlined"
        name={name}
        value={value}
        onChange={handleChange}
        error={hasError}
        select={select}
        helperText={
          hasError ? (
            <StyledErrorMessage>
              {formik.touched[name] && formik.errors[name]}
            </StyledErrorMessage>
          ) : (
            ""
          )
        }
        onBlur={handleBlur}
        disabled={disabled}
        width={width}
        {...props}
        InputProps={{
          endAdornment: copyValue ? (
            <InputAdornment position="end">
              <Tooltip
                title="Copied to clipboard!"
                placement="top"
                open={isCopied}
              >
                <StyledIconButton onClick={handleCopy} error={hasError}>
                  <FileCopy />
                </StyledIconButton>
              </Tooltip>
            </InputAdornment>
          ) : null
        }}
      >
        {list}
      </StyledTextField>
    </StyledBox>
  );
};

LabelTextField.propTypes = {
  copyValue: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  formik: PropTypes.object,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rootWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  list: PropTypes.array,
  select: PropTypes.bool
};
